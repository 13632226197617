@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/bootstrap/scss/mixins";
@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// website colors
$font-family-base : 'Libre Franklin',
sans-serif !important;
$white: #fff;
$white-90: rgba(255, 255, 255, 0.90);
$white-75: rgba(255, 255, 255, 0.75);
$white-50: rgba(255, 255, 255, 0.50);
$white-25: rgba(255, 255, 255, 0.25);
$white-10: rgba(255, 255, 255, 0.10);

$primary: #44D86A;
$primary-darker: #2bca53;
$primary-90: rgba(68, 216, 105, 0.90);
$primary-75: rgba(68, 216, 105, 0.75);
$primary-50: rgba(68, 216, 105, 0.50);
$primary-25: rgba(68, 216, 105, 0.25);
$primary-10: rgba(68, 216, 105, 0.10);

$secondary: #16313A;
$secondary-90: rgba(22, 49, 58, 0.90);
$secondary-75: rgba(22, 49, 58, 0.75);
$secondary-50: rgba(22, 49, 58, 0.50);
$secondary-25: rgba(22, 49, 58, 0.25);
$secondary-10: rgba(22, 49, 58, 0.10);

//fonts 




$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark
);

.number {
  // font-size: 1rem;
  font-size: $font-size-base;
}

.text-light {
  font-weight: 100;
}


@for $i from 1 through 6 {

  h#{$i},
  .h#{$i} {
    font-size: (24 - 2*($i - 1)) +px !important;
    position: relative;
    color: $secondary;

    &.h-header {
      text-align: center !important;
    }

    &.h-header:after {
      position: absolute;
      content: '';
      height: 3px;
      bottom: -3px;
      margin: 0 auto;
      left: 0;
      right: 0;
      width: 70px;
      background-color: $primary;
    }
  }
}

b {
  font-size: $font-family-base;
}